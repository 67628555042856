/*------------------------------------*
  Button
*------------------------------------*/
.btn {
  @apply font-sans;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 1rem 2rem;
  overflow: hidden;
  font-size: 0.85rem;
  font-weight: 500;
  color: theme('colors.green.DEFAULT');
  text-align: center;
  letter-spacing: 0.0225rem;
  background-color: theme('colors.grey.DEFAULT');
  border-radius: 18.75rem;

  @screen xs {
    width: auto;
  }

  @screen s {
    padding: 1.56rem 2.81rem;
    font-size: 1.125rem;
  }

  &:focus {
    color: theme('colors.grey.DEFAULT');
    background-color: theme('colors.green.DEFAULT');
    outline: none;
    box-shadow: inset 0px 0px 0px 4px theme('colors.green.dark');
    /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
    transition: 0.3s all theme('transitionTimingFunction.out-expo');
  }
}

.btn--secondary {
  padding: 10px 20px;
  font-size: 1.25rem;
  white-space: nowrap;
  background-color: white;
  border-radius: 9999px;
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: 0.3s all theme('transitionTimingFunction.out-expo');

  &:hover {
    background-color: theme('colors.green.dark');
  }

  &:focus {
    background-color: theme('colors.green.DEFAULT');
    outline: none;
  }

  &.is-active {
    background-color: theme('colors.green.DEFAULT');
  }
}
