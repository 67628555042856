/*------------------------------------*
  Layout
*------------------------------------*/
.container {
  max-width: 94rem;
  margin: 0 auto;
  padding-right: 2rem;
  padding-left: 2rem;

  @screen m {
    max-width: 104rem;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

::selection {
  @apply bg-terracotta text-terracotta-light;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: theme('colors.terracotta.light');
  @apply font-sans;
}

.footer-logo-studiometa svg {
  width: 9rem;
  height: auto;
}

strong {
  font-weight: 700;
}
