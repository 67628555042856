.form__label {
  padding-bottom: 0.5rem;
}

%form__input {
  position: relative;
  padding: 18px 24px;
  background-color: transparent;
  border: 1px solid theme('colors.beige.brown');
  border-radius: 20px;

  &:focus {
    border: 1px solid theme('colors.grey.dark');
    outline: none;
  }
}

.form__input {
  @extend %form__input;
}

.form__input--file {
  @extend %form__input;
  padding-left: 45px;
  cursor: pointer;

  &::file-selector-button {
    display: none;
  }

  &::before {
    content: url('../../../public/img/paper-clip.svg');
    position: absolute;
    left: 18px;
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 15px;
  }
}
