/*------------------------------------*
  h-slider
  Horizontal Slider
*------------------------------------*/

.h-slider {
  @screen s {
    height: 300vw;
  }
}

.h-slider__element {
  width: 100%;

  @screen s {
    width: calc(300vw + 768px);
    transform: translateX(calc(-100% * var(--progress) + 100vw * var(--progress)));
  }
}

.h-slider__item {
  @screen l {
    transform: translateY(-32px);
  }
}

.h-slider__item-content {
  width: 80vw;
  height: 22rem;

  @screen l {
    height: 27rem;
  }

  @screen xxl {
    height: 33rem;
  }
}

.h-slider__slide-title {
  transform: translateY(12px);

  @screen s {
    transform: translateY(calc(50% + 7px));
  }

  @screen m {
    transform: translateY(calc(50% + 11px));
  }

  @screen l {
    transform: translateY(calc(50% + 14px));
  }
}
