/*------------------------------------*
  Testimony
*------------------------------------*/

.testimony__title {
  position: relative;
  padding-bottom: 2rem;
  opacity: 0.5;
  transition: 0.6s opacity theme('transitionTimingFunction.out-quad'),
    0.6s transform theme('transitionTimingFunction.out-quad');

  > a {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.4s transform theme('transitionTimingFunction.out-quad');
    }

    &:focus {
      outline: none;
      opacity: 1;

      &:before {
        content: '';
        opacity: 0.5;
        transform: scaleX(1);
      }
    }
  }

  &.is-active {
    opacity: 1;

    a:before {
      content: '';
      transform: scaleX(1);
    }
  }

  &.is-hidden {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.testimony__sticky {
  transform: translateY(calc(-85px * var(--current-testimony)));
  transition: 0.6s transform theme('transitionTimingFunction.out-quad');
}
